import React from 'react';
import "./footer.styl"

const thisYear = new Date().getFullYear();

export default function Footer() {
  return (
    <div className="lbp-footer">
      <p className="lbp-footer-copyright">©{thisYear} Little Book Parade</p>
    </div>
  );
};