import React, { useRef } from 'react';

const validateUri = uri => {
  const uriRegex = /https?\:\/\/[a-zA-Z0-9-.]+\.[a-zA-Z0-9]{2,}/;
  return uriRegex.test(uri);
};

const UrlInput = props => {
  const inputRef = useRef();

  const handleCancel = evt => {
    props.onCancel();
  }

  const handleSubmit = evt => {
    const uri = inputRef.current.value;
    if (validateUri(uri)) {
      props.onUpdate(uri);
    }
  };

  const handleUpload = () => {};

  return (
    <form className="lbp-url-input">
      <input ref={inputRef} defaultValue={props.uri}></input>
      <i className="lbp-url-input-confirm fas fa-file-upload" onClick={handleUpload}></i>
      <i className="lbp-url-input-confirm fas fa-check-circle" onClick={handleSubmit}></i>
      <i className="lbp-url-input-cancel fas fa-undo-alt" onClick={handleCancel}></i>
    </form>
  )
}

UrlInput.defaultProps = {
  onCancel() {},
  onUpdate() {}
};

export default UrlInput;