import React, { useState } from "react";
import Immutable from "immutable";
import { DefaultDraftBlockRenderMap, CompositeDecorator, EditorState } from "draft-js";
import UrlInput from "../components/url-input";

const getEntityFinderByType = (type, contentState) => range => {
  const entityKey = range.getEntity();
  if (entityKey) {
    const entity = contentState.getEntity(entityKey);
    return entity.type === type;
  }
  return false;
};

const BookTitle = props => {
  return <a className="lbp-book-title" href="#">{props.title}</a>
};

const bookTitleStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    getEntityFinderByType('BOOK.TITLE', contentState),
    callback
  );
}


const imageStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    getEntityFinderByType('ARTICLE.IMAGE', contentState),
    callback
  );
}

const SimpleLink = props => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return <a href={url}>{props.children}</a>;
}

const linkStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    getEntityFinderByType('LINK', contentState),
    callback
  );
}

export const compositeDecorator = new CompositeDecorator([
  {
    strategy: bookTitleStrategy,
    component: BookTitle
  }, {
    strategy: linkStrategy,
    component: SimpleLink,
  }
]);

const customBlockRenderMap = Immutable.Map({
  paragraph: {
    element: 'p'
  }
});

export const blockRenderMap = DefaultDraftBlockRenderMap.merge(customBlockRenderMap);

const EditorImage = props => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    block,
    contentState,
    handleImageCancel,
    handleImageEdit,
    handleImageUpdate
  } = props;
  const entityKey = block.getEntityAt(0);
  const firstEntity = contentState.getEntity(entityKey);
  const {src} = firstEntity.getData();

  const handleCancel = () => {
    handleImageCancel();
    setIsEditing(false);
  };

  const handleClick = evt => {
    handleImageEdit();
    setIsEditing(true);
  };

  const handleUpdate = url => {
    handleImageUpdate({entityKey, url})
    setIsEditing(false);
  };

  return isEditing
    ? <UrlInput uri={src} onCancel={handleCancel} onUpdate={handleUpdate} />
    : <img className="lbp-article-image" src={src} onClick={handleClick} />;
}

const EditorImageWithState = extraProps => props => {
  const modifiedProps = {...extraProps, ...props};
  return (<EditorImage {...modifiedProps} />);
}

const SimpleImage = props => {
  const {block, contentState} = props;
  const entityKey = block.getEntityAt(0);
  const firstEntity = contentState.getEntity(entityKey);
  const {src} = firstEntity.getData();
  return <img className="lbp-article-image" src={src} />;
}

export const blockRendererFn = contentBlock => {
  const type = contentBlock.getType();
  if (type === 'atomic') {
    return {
      component: SimpleImage,
      editable: false
    };
  }
}

export const getEditorBlockRendererFn = config => contentBlock => {
  const {
    handleImageCancel,
    handleImageEdit,
    handleImageUpdate
  } = config;
  const type = contentBlock.getType();
  if (type === 'atomic') {
    return {
      component: EditorImageWithState({
        handleImageCancel,
        handleImageEdit,
        handleImageUpdate
      }),
      editable: false
    };
  }
}
