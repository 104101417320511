import {Auth} from "aws-amplify";

const defaultFetchOptions = {
  mode: 'cors'
};
const noop = () => {};

export function fetchContentEffect(config = {}) {
  return function() {
    const successHandler = config.successHandler || noop;
    const errorHandler = config.errorHandler || noop;
    const fetchOptions = {...defaultFetchOptions, ...config.fetchOptions}
    fetch(config.path, fetchOptions)
      .then(res => res.json())
      .then(successHandler)
      .catch(errorHandler);
  };
}

export function fetchPrivateContentEffect(config = {}) {
  return () => {
    (async function() {
      const currentSession = await Auth.currentSession()
      const idToken = currentSession.getIdToken().getJwtToken();
      const headers = {
        Authorization: `Bearer ${idToken}`
      };

      const successHandler = config.successHandler || noop;
      const errorHandler = config.errorHandler || noop;
      const fetchOptions = {...defaultFetchOptions, ...config.fetchOptions, headers}
      fetch(config.path, fetchOptions)
        .then(res => res.json())
        .then(successHandler)
        .catch(errorHandler);
    })();
  }
}
